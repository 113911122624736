import './App.css';
import AppNavbar from "./components/AppNavbar";
import 'bootstrap/dist/css/bootstrap.css';
import BarcodeSelector from "./components/BarcodeSelector";
import {Provider} from "react-redux";
import store from "./reducer/store";
import Notifications from "./components/Notifications";
import CardContainer from "./components/CardContainer";
import DataModal from "./components/DataModal";
import ImageModal from "./components/ImageModal";
import StorageComponent from "./components/StorageComponent";

function App() {
    return (
        <Provider store={store}>
            <div>
                <Notifications/>
                <AppNavbar/>
                <BarcodeSelector/>
                <CardContainer/>
                <ImageModal/>
                <DataModal/>
                <StorageComponent/>
            </div>
        </Provider>
    )
        ;
}

export default App;
