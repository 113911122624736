import {Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";

let DataModal = () => {

    const dataModal = useSelector((state) => state.dataModal);
    const dispatch = useDispatch();

    return <Modal show={dataModal.open} centered onHide={() => {
        dispatch({
            type: "CLOSE_DATA_MODAL"

        })
    }}>
        <Modal.Header closeButton>
            <Modal.Title>Data for {dataModal.data.requestId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4>Data:</h4>
            <p>
                status: {dataModal.data.status}
            </p>
            <p>
                documentType: {dataModal.data.body && dataModal.data.body.documentType}
            </p>
            <p>
                docNumber: {dataModal.data.body && dataModal.data.body.docNumber}
            </p>
            {dataModal.data.status === "SUCCESS" &&
                <>
                    <p>
                        taxpayerNumber:{dataModal.data.body && dataModal.data.body.taxpayerNumber}
                    </p>
                    <p>
                        firstName: {dataModal.data.body && dataModal.data.body.firstName}
                    </p>
                    <p>
                        middleName: {dataModal.data.body && dataModal.data.body.middleName}
                    </p>
                    <p>
                        lastName: {dataModal.data.body && dataModal.data.body.lastName}
                    </p>
                    <p>
                        birthday: {dataModal.data.body && dataModal.data.body.birthday}
                    </p>
                    <p>
                        birthPlace: {dataModal.data.body && dataModal.data.body.birthPlace}
                    </p>
                    <p>
                        gender: {dataModal.data.body && dataModal.data.body.gender}
                    </p>
                    <p>
                        nationality: {dataModal.data.body && dataModal.data.body.nationality}
                    </p>
                    <p>
                        issueDate: {dataModal.data.body && dataModal.data.body.issueDate}
                    </p>
                    <p>
                        expiryDate: {dataModal.data.body && dataModal.data.body.expiryDate}
                    </p>
                </>}
        </Modal.Body>
    </Modal>
}
export default DataModal
