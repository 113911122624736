import {Button, Container, FloatingLabel, Form, InputGroup} from "react-bootstrap";
import {Search} from "react-bootstrap-icons";
import {useDispatch, useSelector} from "react-redux";
import {documentUrl} from "../api/api";

let BarcodeSelector = () => {

    const dispatch = useDispatch();
    const barcode = useSelector((state) => state.barcode);
    const documentRequest = useSelector((state) => state.getDocumentRequest)

    return <Container style={{marginTop: 60}}>
        <h1>Get player info from DIIA</h1>
        <Form className={"p-0 m-0"} onSubmit={
            e => {
                e.preventDefault()
                dispatch({
                    type: "START_DOCUMENT_RESPONSE"
                })
                fetch(documentUrl + barcode, {
                    method: "post"
                })
                    .then(resp => {
                        resp.json().then(
                            js => {
                                dispatch({
                                    type: "SET_DOCUMENT_RESPONSE",
                                    isError: false,
                                    barcode: barcode,
                                    status: resp.status,
                                    body: js
                                })
                            }
                        ).catch(e => {
                            dispatch({
                                type: "SET_DOCUMENT_RESPONSE",
                                isError: true,
                                barcode: barcode,
                                response: e.toString()
                            })
                        })
                    })
                    .catch(e => dispatch({
                        type: "SET_DOCUMENT_RESPONSE",
                        isError: true,
                        barcode: barcode,
                        response: e.toString()
                    }))
            }
        }>
            <InputGroup>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Barcode">
                    <Form.Control type="text" placeholder="1234567890"
                                  value={barcode}
                                  onChange={e => {
                                      dispatch({
                                          type: "SET_BARCODE",
                                          barcode: e.target.value
                                      })
                                  }}
                    />
                </FloatingLabel>
                <Button type={"submit"} className={"w-25"} disabled={documentRequest.isLoading}>
                    <Search className={"me-2"}/>
                    Find
                </Button>
            </InputGroup>
        </Form>
    </Container>
}
export default BarcodeSelector
