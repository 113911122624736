import {Toast, ToastContainer} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";

let Notifications = () => {

    const notifications = useSelector((state) => state.notifications);
    const dispatch = useDispatch();

    let ToastMessage = ({uuid, status, title, message}) => {
        return <Toast bg={status === "SUCCESS" ? "success" : "danger"} onClose={e => {
            e.preventDefault()
            dispatch({
                type: "CLOSE_NOTIFICATION",
                uuid: uuid
            })
        }}>
            <Toast.Header>
                <strong className="me-auto">{title}</strong>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
        </Toast>

    }

    return <ToastContainer position={"top-end"} style={{zIndex: 10, marginTop: 60, position: "fixed"}}>
        {notifications.map(notifi => <ToastMessage key={notifi.uuid} uuid={notifi.uuid} status={notifi.status}
                                                   title={notifi.title}
                                                   message={notifi.message}/>)}
    </ToastContainer>

}
export default Notifications
