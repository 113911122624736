import {Button, Card, Carousel, Container} from "react-bootstrap";
import {useEffect} from "react";
import {documentUrl} from "../api/api";
import {useDispatch, useSelector} from "react-redux";


let ResponseCard = ({requestId}) => {
    const dispatch = useDispatch();
    const document = useSelector((state) => state.documents.find(doc => doc.requestId === requestId))

    let getNewStatus = () => {
        fetch(documentUrl + document.requestId).then(
            response => {
                response.json()
                    .then(js => {
                        dispatch({
                            type: "SET_DOCUMENT_STATE_RESPONSE",
                            requestId: requestId,
                            isError: false,
                            status: response.status,
                            body: js
                        })
                    }).catch(
                    e => {
                        dispatch({
                            type: "SET_DOCUMENT_STATE_RESPONSE",
                            requestId: requestId,
                            isError: true,
                            response: e.toString()
                        })
                    }
                )
            }
        ).catch(
            e => {
                dispatch({
                    type: "SET_DOCUMENT_STATE_RESPONSE",
                    requestId: requestId,
                    isError: true,
                    response: e.toString()
                })
            }
        )
    }

    useEffect(() => {
        if (document.status === "NOT_READY_YET") {
            let timeout = setInterval(getNewStatus, 5000)
            return () => {
                clearInterval(timeout)
            }

        }
    }, [document.status])


    return <Container>
        <Card className={"m-2"}>
            <Carousel>
                {document.body.images.length > 0 ?
                    document.body.images.map(image => <Carousel.Item style={{maxHeight: 300, minHeight: 300}}>
                        <img
                            style={{minWidth: "100%", minHeight: "100%", cursor: "zoom-in"}}
                            onClick={() => dispatch({
                                type: "OPEN_IMAGE_MODAL",
                                image: "data:image/jpeg;base64," + image
                            })}
                            className="d-block w-100"
                            src={"data:image/jpeg;base64," + image}
                            alt="image"
                        />
                    </Carousel.Item>)

                    :
                    <Carousel.Item style={{
                        maxHeight: 300,
                        minHeight: 300,
                    }}>
                    </Carousel.Item>
                }
            </Carousel>
            <Card.Body>
                <Card.Title>RequestId: {document.requestId}</Card.Title>
                <Card.Text>Barcode: {document.barcode}</Card.Text>
                <Card.Text>Status: {document.status}</Card.Text>
                <Button disabled={document.status !== "SUCCESS"}
                        onClick={e => {
                            e.preventDefault()
                            dispatch({
                                type: "OPEN_DATA_MODAL",
                                data: document
                            })
                        }}
                >Show data</Button>
            </Card.Body>
        </Card>
    </Container>
}

export default ResponseCard
