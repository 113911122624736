import {Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";

let ImageModal = () => {

    const dataModal = useSelector((state) => state.imageModal);
    const dispatch = useDispatch();

    return <Modal show={dataModal.open} centered size={"xl"} onHide={() => dispatch({
        type: "CLOSE_IMAGE_MODAL"
    })}>
        <Modal.Header closeButton>
            <Modal.Title>Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <img className={"img-fluid w-100"} style={{
                maxHeight: "100%",
                maxWidth: "100%",
                height: "auto",
                width: "auto"
            }}
                 src={dataModal.image}
                 alt={"image from document"}/>
        </Modal.Body>
    </Modal>
}
export default ImageModal
