import {Button, Navbar} from "react-bootstrap";
import {ReactComponent as Slogan} from "../svg/NovoVision_logo_neg.svg";
import {useDispatch} from "react-redux";

let AppNavbar = () => {
    const dispatch = useDispatch();

    return <Navbar collapseOnSelect expand={"sm"} variant={"dark"} fixed={"top"}
                   style={{backgroundColor: 'var(--admiral-blue)'}}>
        <Navbar.Brand
            className={"mx-2"}
            onClick={e => {
                e.preventDefault()
            }}>
            <Slogan/>
        </Navbar.Brand>
        <Navbar.Toggle/>
        <Navbar.Collapse className={"justify-content-end mx-2"}>
            <Button variant={"danger"} onClick={e => {
                e.preventDefault()
                dispatch({
                    type: "CLEAR",
                })
            }}>Clear</Button>
        </Navbar.Collapse>
    </Navbar>
}
export default AppNavbar
