import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

let StorageComponent = () => {
    const documents = useSelector((state) => state.documents);
    const documentsLoaded = useSelector((state) => state.documentsLoaded);
    const dispatch = useDispatch();

    useEffect(() => {
        if (documentsLoaded) {
            console.log("SETTED ITEMS")
            localStorage.setItem('documents', JSON.stringify(documents));
        } else {
            let docs = localStorage.getItem("documents")
            console.log("loads ITEMS")

            if (docs != null && docs.length > 0) {
                console.log("loaded ITEMS")

                dispatch({
                    type: "LOAD_DOCUMENTS",
                    documents: JSON.parse(localStorage.getItem("documents"))
                })
            } else {
                dispatch({
                    type: "LOAD_DOCUMENTS",
                    documents: []
                })
            }
        }
    }, [documents])

    return <></>
}
export default StorageComponent
