import {Container, Row} from "react-bootstrap";
import ResponseCard from "./ResponseCard";
import {useSelector} from "react-redux";

let CardContainer = () => {

    const documents = useSelector((state) => state.documents);

    return <Container className={"p-2"}>
        <Row className={"row-cols-sm-1 row-cols-md-2 row-cols-lg-3 r"}>
            {
                documents.map(doc => <ResponseCard key={doc.requestId} requestId={doc.requestId}/>)
            }
        </Row>
    </Container>
}
export default CardContainer
