import {applyMiddleware} from "redux";
import {legacy_createStore as createStore} from "redux";

import thunk from "redux-thunk";
import rootReducer, {init_state} from "./index";

let createMyStore = () => {
    return createStore(
        rootReducer,
        {...init_state},
        applyMiddleware(thunk)
    );
}

const store = createMyStore()
export default store;
