const {v4: uuidv4} = require('uuid');


export const init_state = {
    barcode: "",
    getDocumentRequest: {
        isLoading: false,
    },
    notifications: [],
    documents: [],
    documentsLoaded: false,
    dataModal: {
        open: false,
        data: {}
    },
    imageModal: {
        open: false,
        image: ""
    }
};

const notification = () => {
    return {
        uuid: uuidv4(),
        status: "",
        title: "",
        message: "",
    }
}
const document = () => {
    return {
        requestId: "",
        barcode: "",
        status: "",
        exception: "",
        message: "",
        body: {
            taxpayerNumber: "",
            firstName: "",
            middleName: "",
            lastName: "",
            birthday: "",
            birthPlace: "",
            docNumber: "",
            gender: "",
            nationality: "",
            documentType: "",
            issueDate: "",
            expiryDate: "",
            images: []
        }
    }
}

export default function rootReducer(state, action) {
    switch (action.type) {
        case "SET_BARCODE":
            state.barcode = action.barcode
            return {...state}
        case "SET_DOCUMENT_RESPONSE":
            state.getDocumentRequest.isLoading = false
            let response = notification()
            if (action.isError) {
                response.status = "ERROR"
                response.title = "UNKNOWN"
                response.message = action.response.toString()
            } else {
                if (action.status === 201) {
                    response.status = "SUCCESS"
                    response.title = "SUCCESS"
                    response.message = "requestId:" + action.body.requestId
                    let doc = document()
                    doc.requestId = action.body.requestId
                    doc.barcode = action.barcode
                    doc.status = "NOT_READY_YET"
                    state.documents = [{...doc}, ...state.documents]
                } else {
                    response.status = "ERROR"
                    response.title = action.body.exception
                    response.message = "status: " + action.status + "\nmessage: " + action.body.message
                }
            }
            state.notifications = [{...response}, ...state.notifications]
            state.getDocumentRequest = {...state.getDocumentRequest}
            return {...state}
        case "SET_DOCUMENT_STATE_RESPONSE":
            let notif = notification()
            let doc = state.documents.find(doc => doc.requestId === action.requestId)
            let docIndex = state.documents.findIndex(doc => doc.requestId === action.requestId)
            if (action.isError) {
                notif.status = "ERROR"
                notif.title = "UNKNOWN in " + action.barcode
                notif.message = action.response.toString()
                state.notifications = [{...notif}, ...state.notifications]
            } else {
                if (action.status === 200) {
                    doc.status = "SUCCESS"
                    doc.barcode = action.body.barcode
                    doc.body.images = [...action.body.file.fileContent]
                    if (action.body.image != null && action.body.image.fileContent != null) {
                        doc.body.images = [action.body.image.fileContent, ...doc.body.images]
                    }
                    doc.body.taxpayerNumber = action.body.body.taxpayerNumber
                    doc.body.firstName = action.body.body.firstName
                    doc.body.middleName = action.body.body.middleName
                    doc.body.lastName = action.body.body.lastName
                    doc.body.birthday = action.body.body.birthday
                    doc.body.birthPlace = action.body.body.birthPlace
                    doc.body.docNumber = action.body.body.docNumber
                    doc.body.gender = action.body.body.gender
                    doc.body.nationality = action.body.body.nationality
                    doc.body.documentType = action.body.body.documentType
                    doc.body.issueDate = action.body.body.issueDate
                    doc.body.expiryDate = action.body.body.expiryDate
                    state.documents[docIndex] = {...doc}
                    notif.status = "SUCCESS"
                    notif.title = "SUCCESSFULLY FOR " + action.barcode
                    notif.message = "Got successful response for " + action.requestId
                    state.notifications = [{...notif}, ...state.notifications]
                } else {
                    switch (action.body.exception) {
                        case "NOT_READY_YET":
                            break
                        case "WRONG_DOCUMENT_TYPE":
                        case "UNKNOWN_DOCUMENT_TYPE":
                            doc.body.documentType = action.body.message
                        case "DOCUMENT_REQUEST_NOT_FOUND":
                        case "UNKNOWN":
                            notif.status = "ERROR"
                            notif.title = action.body.exception + " in " + action.barcode
                            notif.message = action.body.message
                            doc.status = action.status
                            break
                        default:
                            notif.status = "ERROR"
                            notif.title = "UNKNOWN in " + action.barcode
                            notif.message = "UNKNOWN ERROR"
                            state.notifications = [{...notif}, ...state.notifications]
                    }
                }
            }
            state.documents = [...state.documents]
            return {...state}
        case "CLOSE_NOTIFICATION":
            state.notifications = [...state.notifications.filter(notifi => notifi.uuid !== action.uuid)]
            return {...state}
        case "START_DOCUMENT_RESPONSE":
            state.getDocumentRequest.isLoading = true
            state.getDocumentRequest = {...state.getDocumentRequest}
            return {...state}
        case "OPEN_DATA_MODAL":
            state.dataModal.open = true
            state.dataModal.data = action.data
            state.dataModal = {...state.dataModal}
            return {...state}
        case "CLOSE_DATA_MODAL":
            state.dataModal.open = false
            state.dataModal = {...state.dataModal}
            return {...state}
        case "OPEN_IMAGE_MODAL":
            state.imageModal.open = true
            state.imageModal.image = action.image
            state.imageModal = {...state.imageModal}
            return {...state}
        case "CLOSE_IMAGE_MODAL":
            state.imageModal.open = false
            state.imageModal = {...state.imageModal}
            return {...state}
        case "LOAD_DOCUMENTS":
            state.documents = action.documents
            state.documentsLoaded = true
            return {...state}
        case "CLEAR":
            return {...init_state}
        default:
            return {...state}
    }
}
